import * as actionTypes from './actionTypes';

export const openModal = (values) => {
  return {
    type: actionTypes.OPEN_MODAL,
    payload: values
  };
};

export const replaceModal = (values) => {
  return {
    type: actionTypes.REPLACE_MODAL,
    payload: values
  };
};

export const closeModal = (values) => {
  return {
    type: actionTypes.CLOSE_MODAL,
    payload: values
  };
};

export const closeAllModals = () => {
  return {
    type: actionTypes.CLOSE_ALL_MODALS
  };
};
