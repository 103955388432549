import * as actionTypes from '../actions/actionTypes';
import { success, error, abort } from 'redux-saga-requests';

const initialState = {
  data: null,
  pending: 0,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_MENU:
      return { ...state, pending: state.pending + 1, error: null };

    case success(actionTypes.FETCH_MENU):
      return {
        ...state,
        data: action.data,
        pending: state.pending - 1,
        error: null
      };

    case error(actionTypes.FETCH_MENU):
      return {
        ...state,
        pending: state.pending - 1,
        error: action.error || 'error',
        data: null
      };

    case abort(actionTypes.FETCH_MENU):
      return { ...state, pending: state.pending - 1 };

    default:
      return state;
  }
};

export default reducer;
