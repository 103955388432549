import React from 'react';
import {
  put,
  call,
  delay,
  take,
  race,
  // debounce,
  throttle,
  select
} from 'redux-saga/effects';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { SubmissionError } from 'redux-form';
import { push } from 'connected-react-router';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Modal, message, Tooltip, Statistic } from 'antd';
import { store } from '../index';
import Moment from 'react-moment';

import * as actions from '../actions/index';
import * as actionTypes from '../actions/actionTypes';
import { success } from 'redux-saga-requests';
import { actions as idleActions } from '../components/idle';
import reloadAndClearCache from '../../components/Utils/ReloadAndClearCache';

const { Countdown } = Statistic;

export function* logoutSaga(action) {
  // console.log('Initiating logout', action);
  const { text, dontClear = false } = action.meta || {};
  if (!dontClear) yield put(actions.logoutClear());
  yield call(removeAuthTokenFromStorage);
  yield call(removeAuthTokenFromSessionStorage);
  yield call(message.destroy);
  yield call(Modal.destroyAll);
  yield put(actions.logoutSucceed());
  yield call(({ text, duration }) => message.success(text, duration), {
    text: text || 'Użytkownik został wylogowany.',
    duration: 0
  });
}

export function* redirectPathSaga(action) {
  if (
    action.payload.action === 'REPLACE' &&
    (action.payload.location.pathname === '/login/' ||
      action.payload.location.pathname === '/login') &&
    action.payload.location.state &&
    action.payload.location.state.referrer
  ) {
    yield put(
      actions.setAuthRedirectPath(action.payload.location.state.referrer)
    );
  }
}

export function* authenticateUserSaga({ values, resolve, reject }) {
  yield put(actions.authStart());
  yield call(message.destroy);
  try {
    const credentials = {
      userName: values.username,
      password: values.password
    };
    // Get token response from server
    const response = yield call(requestToken, credentials);
    const token = response.data;

    const decodedToken = yield jwtDecode(token['access_token']);

    const authRedirectPath = yield select(
      (state) => state.auth.authRedirectPath
    );
    const {
      pathname = '/',
      hash = '',
      search = '',
      roleId = ''
    } = authRedirectPath || {};

    yield put(
      actions.authSuccess(
        token['access_token'],
        token['refresh_token'],
        decodedToken
      )
    );
    // Redirect to accessed url
    if (
      roleId &&
      decodedToken &&
      decodedToken.roleId &&
      roleId !== decodedToken.roleId
    ) {
      yield put(push({ pathname: '/', hash: '', search: '' }));
    } else {
      yield put(push({ pathname, hash, search }));
    }

    // Persist response to localstorage for persistence (including across tabs)
    if (
      decodedToken &&
      decodedToken.programId === '7651f192-5247-4a85-9ed3-5e510bf96167'
    ) {
      yield call(setAuthTokenToSessionStorage, token);
    } else {
      yield call(setAuthTokenToStorage, token);
    }

    // yield call(resolve);

    return {
      refresh_token: token['refresh_token'],
      access_token: token['access_token'],
      expires_in: token['expires_in'],
      decodedToken: decodedToken
    };
  } catch (error) {
    console.log(error);
    let errorMessage = JSON.stringify(error);
    if (error.response && error.response.data) {
      if (error.response.data.message) {
        errorMessage = error.response.data.message;
      } else {
        errorMessage = error.response.data;
      }
    }
    yield put(actions.authFail(errorMessage));
    yield call(reject, new SubmissionError({ _error: errorMessage }));
  }
}

export function* restoreAuthenticationSaga(storageEvent = false) {
  let token = yield call(getAuthTokenFromStorage);

  if (!token) token = yield call(getAuthTokenFromSessionStorage);

  yield call(message.destroy);

  if (token) {
    const decodedToken = yield jwtDecode(token['access_token']);

    const expirationDate = decodedToken.exp;
    const dateNow = yield Math.floor(Date.now() / 1000);

    if (expirationDate <= dateNow) {
      yield put(
        actions.logout({
          text: 'Sesja wygasła, użytkownik został wylogowany',
          reason: 'Token expired',
          expirationDate,
          dateNow
        })
      );
    } else {
      yield put(
        actions.authRestore(
          token['access_token'],
          token['refresh_token'],
          decodedToken
        )
      );
      const authRedirectPath = yield select(
        (state) => state.auth.authRedirectPath
      );
      const { pathname = '/', hash = '', search = '' } = authRedirectPath || {};
      if (pathname === '/modal/') yield put(push({ pathname, hash, search }));

      return {
        refresh_token: token['refresh_token'],
        access_token: token['access_token'],
        expires_in: token['expires_in'],
        decodedToken: decodedToken
      };
    }
  } else if (!token && storageEvent) {
    yield put(
      actions.logout({ reason: 'No token in localstorage', dontClear: true })
    );
  }
}

function* tokenExpiryCheckBackgroundTask() {
  while (true) {
    const expirationDate = yield select((state) => state.auth.user.exp);
    const dateNow = yield Math.floor(Date.now() / 1000);
    // console.log('token check', expirationDate, dateNow);
    if (expirationDate <= dateNow) {
      yield put(
        actions.logout({ reason: 'Token expired', expirationDate, dateNow })
      );
    }

    yield delay(1000);
  }
}

export function* startBackgroundTokenExpiryCheck() {
  while (true) {
    yield take([actionTypes.AUTH_SUCCESS, actionTypes.AUTH_RESTORE]);
    yield race({
      task: call(tokenExpiryCheckBackgroundTask),
      cancel: take(actionTypes.AUTH_INITIATE_LOGOUT)
    });
  }
}

export function* authFlowSaga(action) {
  let userSignedOut;
  let token = yield call(restoreAuthenticationSaga);
  yield put(actions.checkVersion());

  while (true) {
    yield put(idleActions.stop());
    while (!token) {
      const { login, storageEvent } = yield race({
        login: take(actionTypes.AUTH_USER),
        storageEvent: take(actionTypes.AUTH_STORAGE_CHANGE)
      });

      if (login) {
        token = yield call(authenticateUserSaga, login.payload);
      } else if (storageEvent) {
        token = yield call(restoreAuthenticationSaga, storageEvent);
      }
    }
    userSignedOut = false;
    yield put(actions.fetchMenu());
    yield put(actions.getAllowedPrograms());
    yield put(actions.checkVersion());
    yield put(idleActions.start());

    while (!userSignedOut) {
      // Randomize so that multiple tabs dont request refresh at the same time
      const dateNow = yield Math.floor(Date.now() / 1000);
      const randomize = yield Math.floor(Math.random() * 50);
      const tokenExpiry =
        (token.decodedToken.exp - dateNow - randomize - 10) * 1000;

      const {
        expired,
        programChange,
        passwordChange,
        storageEvent,
        roleChange,
        fleetChange,
        updateToken
      } = yield race({
        expired: delay(tokenExpiry),
        programChange: take(success(actionTypes.SET_PROGRAM)),
        roleChange: take(success(actionTypes.SET_ROLE)),
        fleetChange: take(success(actionTypes.SET_FLEET)),
        passwordChange: take(success(actionTypes.CHANGE_PASSWORD)),
        storageEvent: take(actionTypes.AUTH_STORAGE_CHANGE),
        updateToken: take(actionTypes.UPDATE_TOKEN),
        // expired: delay(10000),
        signout: take(actionTypes.AUTH_INITIATE_LOGOUT)
      });
      yield put(actions.checkVersion());

      // token expired first
      if (expired) {
        token = yield call(refreshToken, token);
        // authorization failed, either by the server or the user signout
        if (!token) {
          userSignedOut = true; // breaks the loop
        }
      } else if (programChange) {
        token = yield call(refreshToken, token);
        yield put(actions.deleteDynamicPageCache());
        // authorization failed, either by the server or the user signout
        if (!token) {
          userSignedOut = true; // breaks the loop
        }
        yield put(actions.fetchMenu());
        if (programChange.meta.redirect) yield put(push('/'));
        // yield put(actions.getAllowedPrograms());
      } else if (roleChange) {
        token = yield call(refreshToken, token);
        yield put(actions.deleteDynamicPageCache());

        // authorization failed, either by the server or the user signout
        if (!token) {
          userSignedOut = true; // breaks the loop
        }
        yield put(actions.fetchMenu());
        yield call(reloadPostFilterChangeSaga, {
          initial: false,
          redirect: true
        });
        // yield put(push('/'));
        // yield put(actions.getAllowedPrograms());
      } else if (updateToken) {
        token = yield call(refreshToken, token);
        // authorization failed, either by the server or the user signout
        if (!token) {
          userSignedOut = true; // breaks the loop
        }
        // yield put(actions.fetchMenu());
        // yield call(reloadPostFilterChangeSaga, { initial: false });
        // yield put(push('/'));
        // yield put(actions.getAllowedPrograms());
      } else if (fleetChange) {
        token = yield call(refreshToken, token);
        // authorization failed, either by the server or the user signout
        if (!token) {
          userSignedOut = true; // breaks the loop
        }
        yield put(actions.fetchMenu());
        yield call(reloadPostFilterChangeSaga, { initial: false });
        // yield put(push('/'));
        // yield put(actions.getAllowedPrograms());
      } else if (passwordChange) {
        token = yield call(refreshToken, token);
        // authorization failed, either by the server or the user signout
        if (!token) {
          userSignedOut = true; // breaks the loop
        }
      } else if (storageEvent) {
        token = yield call(restoreAuthenticationSaga, storageEvent);
        if (!token) {
          userSignedOut = true; // breaks the loop
        } else {
          yield put(actions.fetchMenu());
          yield put(actions.getAllowedPrograms());
        }
      }
      // user signed out before token expiration
      else {
        token = null;
        userSignedOut = true; // breaks the loop
        yield put(actions.authSagaRestart({ reason: 'User signed out' }));
      }
    }
  }
}

function* refreshToken(tokenObj) {
  const expirationDate = tokenObj.decodedToken.exp;
  const dateNow = yield Math.floor(Date.now() / 1000);

  if (expirationDate <= dateNow) {
    yield put(
      actions.logout({ reason: 'Token expired', expirationDate, dateNow })
    );
  } else {
    try {
      const response = yield call(refreshTokenRequest, tokenObj);
      const token = response.data;

      // yield call(setAuthTokenToStorage, token);

      const decodedToken = yield jwtDecode(token['access_token']);

      if (
        decodedToken &&
        decodedToken.programId === '7651f192-5247-4a85-9ed3-5e510bf96167'
      ) {
        yield call(setAuthTokenToSessionStorage, token);
        yield call(removeAuthTokenFromStorage);
      } else {
        yield call(setAuthTokenToStorage, token);
        yield call(removeAuthTokenFromSessionStorage);
      }

      yield put(
        actions.authRefresh(
          token['access_token'],
          token['refresh_token'],
          decodedToken
        )
      );
      return {
        refresh_token: token['refresh_token'],
        access_token: token['access_token'],
        expires_in: token['expires_in'],
        decodedToken: decodedToken
      };
    } catch (error) {
      console.log(error);
      yield put(actions.authFail(error.response.data));
      yield put(actions.logout({ reason: 'Error', error }));
      return null;
    }
  }
}

const FrontendVersion = process.env.REACT_APP_GIT_DATE || 'Localhost';

const requestToken = (credentials) => {
  return axios({
    method: 'post',
    url: '/api/identity/sign-in',
    data: credentials,
    headers: {
      FrontendVersion
    }
  });
};

const refreshTokenRequest = (token) => {
  return axios({
    method: 'post',
    url: `/api/identity/refresh-tokens/${token['refresh_token']}`,
    data: {},
    headers: {
      Authorization: `Bearer ${token['access_token']}`,
      FrontendVersion
    }
  });
};

const getAuthTokenFromStorage = () => {
  return JSON.parse(localStorage.getItem('authToken'));
};
const getAuthTokenFromSessionStorage = () => {
  return JSON.parse(sessionStorage.getItem('authToken'));
};

const setAuthTokenToStorage = (token) => {
  localStorage.setItem('authToken', JSON.stringify(token));
};
const setAuthTokenToSessionStorage = (token) => {
  sessionStorage.setItem('authToken', JSON.stringify(token));
};

const removeAuthTokenFromStorage = () => {
  localStorage.removeItem('authToken');
};
const removeAuthTokenFromSessionStorage = () => {
  sessionStorage.removeItem('authToken');
};

function* requestVersion(action) {
  yield put(actions.checkVersionRequest());
}

export function* debounceVersionCheck() {
  yield throttle(10000, actionTypes.CHECK_VERSION, requestVersion);
}

export function* versionCheckSaga(action) {
  const envDate = process.env.REACT_APP_GIT_DATE
    ? new Date(process.env.REACT_APP_GIT_DATE)
    : new Date();
  const currentVersion = envDate.valueOf();
  // const envDate = new Date('2021-01-01');
  // const currentVersion = envDate.valueOf();

  const newVersionDate = new Date(action.data);
  // const newVersionDate = new Date('2022-01-01');
  const newVersion = newVersionDate.valueOf();

  const valid = newVersionDate.getTime() > 0;

  const outdated = yield select((state) => state.auth.outdated);
  if (valid && newVersion > currentVersion && outdated === false) {
    yield put(actions.setVersionOutdated('modal'));
    const outdatedTimeValue = yield select((state) => state.auth.outdatedTime);
    const token = yield select((state) => state.auth.token);
    const outdatedTimeDate = new Date(outdatedTimeValue * 1000);
    yield call(() =>
      Modal.confirm({
        title: 'Dostępna jest nowa wersja platformy',
        content: (
          <React.Fragment>
            Automatyczne odświeżenie: <Countdown value={outdatedTimeDate} />
          </React.Fragment>
        ),
        onOk: reloadAndClearCache,
        onCancel: () => {
          store.dispatch(actions.setVersionOutdated('toolbar'));
        },
        icon: <InfoCircleOutlined />,
        style: { top: 45 },
        okText: 'Załaduj nową wersję teraz',
        cancelText: 'Chwilowo pomiń',
        cancelButtonProps: { disabled: token === null }
      })
    );
  }
}

export function* networkErrorSaga(action) {
  const networkErrorState = yield select((state) => state.auth.networkError);
  if (!networkErrorState) {
    yield put(actions.setNetworkError(true));
    var errorMessage = JSON.stringify(action.error);
    if (action && action.error && action.error.message)
      errorMessage = (
        <React.Fragment>
          <Tooltip title={JSON.stringify(action.error)}>
            {action.error.message}
            <br />
            <Moment format="DD.MM.YYYY HH:mm">{new Date()}</Moment>
          </Tooltip>
        </React.Fragment>
      );
    yield call(() =>
      Modal.confirm({
        content: errorMessage,
        title: 'Wystąpił błąd połączenia',
        onOk: reloadAndClearCache,
        onCancel: () => {
          store.dispatch(actions.setNetworkError(false));
        },
        icon: <InfoCircleOutlined />,
        style: { top: 45 },
        width: 500,
        okText: 'Załaduj ponownie - dane nie zostaną zachowane',
        cancelText: 'Pomiń'
      })
    );
  }
}

export function* startBackgroundVersionCheck() {
  yield put(actions.checkVersion());
  while (true) {
    const reloadTime = yield select((state) => state.auth.outdatedTime);
    const dateNow = yield Math.floor(Date.now() / 1000);
    if (reloadTime && reloadTime <= dateNow) {
      reloadAndClearCache();
    }
    yield delay(1000);
    if (reloadTime === false) yield put(actions.checkVersion());
  }
}

export function* reloadPostFilterChangeSaga(action) {
  const { redirect = false } = action;
  if (!action.initial) {
    const location = yield select((state) => state.router.location);
    const { pathname, search, hash } = location;

    const splitHash = hash.split('#');
    if (splitHash.length > 0 && splitHash[1]) {
      const guidRegext = new RegExp(
        '^({{0,1}([0-9a-fA-F]){8}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){12}}{0,1})$'
      );
      const test = guidRegext.test(splitHash[1]);
      if (test)
        yield put(
          actions.fetchDynamicPage(splitHash[1], search, false, redirect)
        );
    }

    const splitPath = pathname.split('/');
    if (splitPath.length > 0 && splitPath[1]) {
      const guidRegext = new RegExp(
        '^({{0,1}([0-9a-fA-F]){8}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){12}}{0,1})$'
      );
      const test = guidRegext.test(splitPath[1]);
      if (test)
        yield put(
          actions.fetchDynamicPage(splitPath[1], search, false, redirect)
        );
    }
  }
}
