import * as actionTypes from '../actions/actionTypes';

export const updateActivity = (objectId) => {
  return {
    type: actionTypes.SEND_ACTIVITY_REQUEST,
    request: {
      url: `domain/activeSessions`,
      method: 'post',
      data: { objectId }
    },
    meta: {
      objectId,
      asPromise: true
    }
  };
};
