import * as actionTypes from '../actions/actionTypes';
import { success, error } from 'redux-saga-requests';

const initialState = {
  open: false,
  loading: false,
  error: false,
  results: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SEARCH_RESULTS:
      return {
        ...state,
        results: [],
        error: false,
        loading: false
      };
    case actionTypes.FETCH_SEARCH_RESULTS_REQUEST:
      return {
        ...state,
        results: [],
        error: false,
        loading: true
      };
    case error(actionTypes.FETCH_SEARCH_RESULTS_REQUEST):
      return {
        ...state,
        results: [],
        error: action.error,
        loading: false
      };
    case success(actionTypes.FETCH_SEARCH_RESULTS_REQUEST):
      return {
        ...state,
        results: action.data.data,
        error: false,
        loading: false
      };
    default:
      return state;
  }
};

export default reducer;
