import { put } from 'redux-saga/effects';

import * as actions from '../actions/index';

export function* updateUISettingsSaga(action) {
  const { data = {} } = action;
  const { settings = {} } = data || {};
  const {
    theme = 'light',
    layout = 'topmenu',
    contentWidth = 'Fixed',
    tasksView = 'dropdown',
    debug = false,
    language = 'pl'
  } = settings || {};
  yield put(
    actions.changeUiSettings({
      navTheme: theme,
      layout,
      contentWidth,
      tasksView,
      debug,
      language
    })
  );
}
