import * as actionTypes from '../actions/actionTypes';

export const getProposalData = (barCode) => ({
  type: actionTypes.PROPOSAL_DATA_BY_BARCODE,
  request: {
    url: `/domain/InsuranceData/ByDocumentNumber/${barCode}`,
    method: 'get',
    data: {}
  },
  meta: {
    asPromise: true
  }
});

export const confirmProposal = (proposalGUID) => ({
  type: actionTypes.PROPOSAL_CONFIRM,
  request: {
    url: `/domain/script/webservice/TVP_ConfirmDocument/${proposalGUID}`,
    method: 'post',
    data: {}
  },
  meta: {
    asPromise: true
  }
});

export const getProposalFileSource = (proposalGUID) => ({
  type: actionTypes.PROPOSAL_POLICY_FILE_SOURCE,
  request: {
    url: `/domain/InsuranceData/GetDocumentUrl/${proposalGUID}`,
    method: 'get',
    data: {}
  },
  meta: {
    asPromise: true
  }
});

export const getPolicyFileData = (proposalGUID) => ({
  type: actionTypes.PROPOSAL_POLICY_FILE_SOURCE,
  request: {
    url: `/domain/InsuranceData/GetDocumentPreviewUrl/${proposalGUID}`,
    method: 'get',
    data: {}
  },
  meta: {
    asPromise: true
  }
});
