import { call, delay, put } from 'redux-saga/effects';
import printJS from 'print-js';
import { saveAs } from 'file-saver';
import contentDisposition from 'content-disposition';

export function* downloadFileSaga(action) {
  if (action.meta.download)
    yield call((link) => window.location.replace(link), action.data);
}

export function* printPdfSaga(action) {
  yield call(
    (pdf) => printJS({ printable: `${pdf}`, type: 'pdf' }),
    action.data
  );
}

export function* saveBlob(action) {
  const parsedContentDisposition = contentDisposition.parse(
    action.data.contentDisposition
  );
  yield call(({ blob, filename }) => saveAs(blob, filename), {
    blob: action.data.blob,
    filename: parsedContentDisposition.parameters.filename
  });
}

export function* delayActionSaga(action) {
  const { actionToDelay, ms = 0 } = action;
  yield delay(ms);
  yield put(actionToDelay);
}
