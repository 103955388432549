const consoleOverride = () => {
  if (process.env.NODE_ENV !== 'production' && console !== undefined) {
    // const _log = console.log;
    const _error = console.error;
    // const _warning = console.warning;

    console.error = function (errMessage) {
      if (errMessage.includes('antd-compatible: Icon')) return;
      _error.apply(console, arguments);
    };

    // console.log = function (logMessage) {
    //   // Do something with the log message
    //   _log.apply(console, arguments);
    // };

    // console.warning = function (warnMessage) {
    //   // do something with the warn message
    //   _warning.apply(console, arguments);
    // };
  }
};

export default consoleOverride;
