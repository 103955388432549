import * as actionTypes from '../actions/actionTypes';

// export const fetchLetterTemplates = (pageId) => ({
//   type: actionTypes.LETTER_TEMPLATES_FETCH,
//   request: {
//     url: `/domain/script/webservice/GetLetterTemplates`,
//     method: 'post',
//     data: {}
//   },
//   meta: {
//     pageId
//   }
// });

export const generateLetterTemplate = ({ letterId, search }) => ({
  type: actionTypes.LETTER_GENERATE_TEMPLATE,
  request: {
    // url: `/DocumentProcessing/Letter/GenerateTemplate/${letterId}`,
    url: `/domain/Letter/PrepareLetter/${letterId}`,
    params: new URLSearchParams(search),
    method: 'get'
  },
  meta: {
    letterId,
    search,
    asPromise: true
  }
});

export const generateLetterTemplateWithPayload = ({
  letterId,
  search,
  data
}) => ({
  type: actionTypes.LETTER_GENERATE_TEMPLATE,
  request: {
    // url: `/DocumentProcessing/Letter/GenerateTemplate/${letterId}`,
    url: `/DocumentProcessing/Letter/GenerateTemplateFromFormData/${letterId}`,
    params: new URLSearchParams(search),
    method: 'post',
    data
  },
  meta: {
    letterId,
    search,
    asPromise: true
  }
});

export const generateEmailReply = ({ journalId, replyTo }) => ({
  type: actionTypes.LETTER_GENERATE_EMAIL_REPLY_TEMPLATE,
  request: {
    url: `/DocumentProcessing/letter/replyToEmail`,
    params: new URLSearchParams({ journalId, replyTo }),
    method: 'get'
  },
  meta: {
    journalId,
    replyTo,
    asPromise: true
  }
});

export const generateLetterTemplateWithSession = ({
  letterId,
  search,
  sessionId
}) => ({
  type: actionTypes.LETTER_GENERATE_TEMPLATE_WITH_SESSION,
  request: {
    // url: `/DocumentProcessing/Letter/GenerateTemplate/${letterId}`,
    url: `/domain/Letter/PrepareLetter/${letterId}/${sessionId}`,
    params: new URLSearchParams(search),
    method: 'get'
  },
  meta: {
    letterId,
    search,
    asPromise: true
  }
});

export const generateLetterPdf = ({ data, search, letterName }) => ({
  type: actionTypes.LETTER_GENERATE_PDF,
  request: {
    url: `/DocumentProcessing/Letter/GeneratePdfFromTemplate`,
    method: 'post',
    params: new URLSearchParams(search),
    responseType: 'blob',
    data
  },
  meta: {
    ...data,
    search,
    letterName,
    asPromise: true
  }
});

export const generateLetterCombinedPrintPdf = ({
  data,
  search,
  letterName
}) => ({
  type: actionTypes.LETTER_GENERATE_COMBINED_PRINT_PDF,
  request: {
    url: `/DocumentProcessing/Letter/GeneratePdfFromTemplateWithFiles`,
    method: 'post',
    params: new URLSearchParams(search),
    responseType: 'blob',
    data
  },
  meta: {
    ...data,
    search,
    letterName,
    asPromise: true
  }
});

export const addPrintJournalEntry = ({ data, search, letterName }) => ({
  type: actionTypes.LETTER_JOURNAL_PRINT_ENTRY,
  request: {
    url: `/DocumentProcessing/Letter/SaveLetterInJournal`,
    method: 'post',
    params: new URLSearchParams(search),
    data: { ...data, addJournalEntry: true }
  },
  meta: {
    ...data,
    search,
    letterName,
    asPromise: true
  }
});

export const sendLetterByEmail = ({ data, search }) => ({
  type: actionTypes.LETTER_SEND_BY_EMAIL,
  request: {
    url: `/DocumentProcessing/Letter/SendTemplateByEmail`,
    params: new URLSearchParams(search),
    method: 'post',
    data
  },
  meta: {
    ...data,
    search,
    asPromise: true
  }
});
