import React from 'react';
import { connect } from 'react-redux';
import plPL from 'antd/es/locale/pl_PL';
import enUS from 'antd/es/locale/en_US';

export const languageMap = {
  pl: {
    selectorLabel: 'Język',
    name: 'Polski',
    position: 0,
    id: 'pl',
    locale: plPL
  },
  en: {
    selectorLabel: 'Language',
    name: 'English',
    position: 1,
    id: 'en',
    locale: enUS
  }
};

export const translateFunction = ({
  s,
  language = 'pl',
  debug = false,
  debugTxt = false
}) => {
  const languageObject = languageMap[language] || languageMap.pl;
  const { position = 0 } = languageObject;

  if (typeof s === 'string' || s instanceof String) {
    const splitString = s.split('||');
    // const splitLength = splitString.length;

    if (!splitString[position]) {
      if (debug)
        return (
          <span style={{ color: 'red', fontWeight: '800' }}>
            {splitString[0]}
          </span>
        );
      if (debugTxt) return `=> ${(splitString[0] || '').toUpperCase()}`;

      return splitString[0];
    } else {
      return splitString[position];
    }
  } else {
    return s;
  }
};

class Translate extends React.Component {
  shouldComponentUpdate(prevProps) {
    if (
      prevProps.s !== this.props.s ||
      prevProps.language !== this.props.language ||
      prevProps.debug !== this.props.debug
    )
      return true;

    return false;
  }
  render() {
    const { s, language, debug } = this.props;
    return translateFunction({ s, language, debug }) || null;
  }
}

// const Translate = ({ s = '', language, debug }) => {
//   const languageObject = languageMap[language] || languageMap.pl;
//   const { position = 0 } = languageObject;

//   if (typeof s === 'string' || s instanceof String) {
//     const splitString = s.split('||');
//     const splitLength = splitString.length;

//     if (!splitLength[position]) {
//       if (debug)
//         return (
//           <span style={{ color: 'red', fontWeight: '800' }}>
//             {splitString[0]}
//           </span>
//         );
//       return splitString[0];
//     } else {
//       return splitString[position];
//     }
//   } else {
//     return s;
//   }
// };

const mapStateToProps = (state) => {
  const uiSettings = { ...state.ui.antSettings, ...state.ui.userSettings };
  const language = uiSettings.language || 'pl';
  const debug = uiSettings.debug || false;
  return {
    language,
    debug
  };
};

export default connect(mapStateToProps, null)(Translate);
