import * as actionTypes from '../actions/actionTypes';

export const fetchDocuments = ({ pageId, queryString }) => ({
  type: actionTypes.DOCUMENTS_FETCH,
  request: {
    url: `/files/Catalog/GetAllFiles${queryString}`,
    method: 'get'
  },
  meta: {
    pageId,
    type: 'documents'
  }
});
export const fetchAllDocuments = ({ pageId, queryString }) => ({
  type: actionTypes.DOCUMENTS_FETCH,
  request: {
    url: `/files/Catalog/GetAllFiles`,
    method: 'get'
  },
  meta: {
    pageId,
    type: 'documents'
  }
});

export const fetchDocumentsByObjectIdTvp = (objectId) => ({
  type: actionTypes.DOCUMENTS_BY_OBJECT_ID_FETCH,
  request: {
    url: `/domain/InsuranceData/GetPolicyFilesByID/${objectId}`,
    method: 'get'
  },
  meta: {
    objectId
  }
});

export const fetchDocumentsByObjectId = ({ pageId, objectId }) => ({
  type: actionTypes.DOCUMENTS_FETCH,
  request: {
    url: `/files/Catalog/GetObjectFiles/${objectId}`,
    method: 'get'
  },
  meta: {
    objectId,
    pageId,
    type: 'documents'
  }
});

export const downloadFile = (fileId, download = true, noParams = false) => ({
  type: actionTypes.DOCUMENTS_DOWNLOAD,
  request: {
    url: `/files/FileStorage/GetPreSignedFileURL/${fileId}${
      noParams ? '?withoutParams=true' : ''
    }`,
    method: 'get'
  },
  meta: {
    fileId,
    download,
    asPromise: !download
  }
});

export const inlineFileUrl = (fileId, download = true, noParams = false) => ({
  type: actionTypes.DOCUMENTS_DOWNLOAD,
  request: {
    url: `/files/FileStorage/GetInlinePreSignedFileURL/${fileId}${
      noParams ? '?withoutParams=true' : ''
    }`,
    method: 'get'
  },
  meta: {
    fileId,
    download,
    asPromise: !download
  }
});

export const inlineSafeHtml = (fileId, download = true) => ({
  type: actionTypes.DOCUMENTS_SAFE_HTML,
  request: {
    url: `/files/FileStorage/PreviewHTMLFile/${fileId}`,
    method: 'get'
  },
  meta: {
    fileId,
    download,
    asPromise: !download
  }
});

export const printPdf = (fileId, download = true) => ({
  type: actionTypes.DOCUMENTS_PRINT_PDF,
  request: {
    url: `/files/FileStorage/GetPreSignedFileURL/${fileId}`,
    method: 'get'
  },
  meta: {
    fileId,
    download
  }
});

export const downloadDocumentsAsZIP = ({ files = [] }) => ({
  type: actionTypes.DOCUMENTS_DOWNLOAD_AS_A_ZIP,
  request: {
    url: `/files/FileStorage/FilesDownloadAsZIP`,
    method: 'post',
    responseType: 'blob',
    data: { Files: files }
  },
  meta: {
    asPromise: true
  }
});

export const delayAction = (actionToDelay, ms) => ({
  type: actionTypes.DELAYED_ACTION,
  actionToDelay,
  ms
});
