import {
  IDLESTATUS_AWAY,
  IDLESTATUS_INACTIVE,
  IDLESTATUS_EXPIRED
} from './constants';
import React from 'react';

import { message, Statistic } from 'antd';
import { logout } from '../../actions/';
const { Countdown } = Statistic;

export const idleStatusDelay = (idleStatus) => (dispatch, getState) => {
  if (idleStatus === IDLESTATUS_AWAY) return 30000; // User becomes away after 20 seconds inactivity
  if (idleStatus === IDLESTATUS_INACTIVE) return 900000; // Call database to look up the users delay time
  if (idleStatus === IDLESTATUS_EXPIRED) return 600000; // Log them out after another minute after they enter the inactive status
};

export const activeStatusAction = (dispatch, getState) => {
  message.destroy();
};

export const idleStatusAction = (idleStatus) => (dispatch, getState) => {
  if (idleStatus === IDLESTATUS_AWAY) {
    // console.info('user is away...');
  }
  if (idleStatus === IDLESTATUS_INACTIVE) {
    const deadline = Date.now() + 600000;
    message.destroy();
    message.warning(
      <div style={{ display: 'inline-block' }}>
        <div style={{ display: 'inline-block' }}>
          Automatyczne wylogowanie za&nbsp;
        </div>
        <div style={{ display: 'inline-block' }}>
          <Countdown
            value={deadline}
            valueStyle={{ fontSize: '14px', color: 'inherit' }}
            format="mm:ss"
          />
        </div>
      </div>,
      0
    );
  }
  if (idleStatus === IDLESTATUS_EXPIRED) {
    dispatch(
      logout({
        reason: 'User inactive',
        text: 'Użytkownik został automatycznie wylogowany.'
      })
    );
  }
};
