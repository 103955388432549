import * as actionTypes from '../actions/actionTypes';

export const importGenericDocument = (payload) => ({
  type: actionTypes.PROCESSING_IMPORT_GENERIC_DOCUMENT,
  request: {
    url: `/DocumentProcessing/Processing/ImportGenericDocument`,
    method: 'post',
    data: payload
  },
  meta: {
    asPromise: true
  }
});

export const importMassDocuments = (payload, objectId = '') => ({
  type: actionTypes.PROCESSING_MASS_DOCUMENT_UPLOAD,
  request: {
    url: `/DocumentProcessing/Processing/MassDocumentImport/${objectId}`,
    method: 'post',
    data: payload
  },
  meta: {
    asPromise: true
  }
});

export const retryMassDocumentImport = (objectId = '') => ({
  type: actionTypes.PROCESSING_RETRY_MASS_DOCUMENT_UPLOAD,
  request: {
    url: `/DocumentProcessing/Processing/MassDocumentImport/RetryImport/${objectId}`,
    method: 'post',
    data: {}
  },
  meta: {
    asPromise: true
  }
});
export const deleteMassDocumentImport = (objectId = '') => ({
  type: actionTypes.PROCESSING_DELETE_MASS_DOCUMENT_UPLOAD,
  request: {
    url: `/DocumentProcessing/Processing/MassDocumentImport/DeleteFiles/${objectId}`,
    method: 'delete',
    data: {}
  },
  meta: {
    asPromise: true
  }
});

export const getSampleImportFile = (guid) => ({
  type: actionTypes.PROCESSING_FETCH_SAMPLE_IMPORT_FILE,
  request: {
    url: `/DocumentProcessing/Processing/GetSampleImportFile/${guid}`,
    method: 'get',
    responseType: 'blob'
  },
  meta: {
    asPromise: true
  }
});

export const generateFile = ({ guid, data }) => ({
  type: actionTypes.PROCESSING_FETCH_SAMPLE_IMPORT_FILE,
  request: {
    url: `/DocumentProcessing/Processing/GenerateFile`,
    method: 'post',
    data: data,
    responseType: 'blob'
  },
  meta: {}
});

export const getGusData = (nip) => ({
  type: actionTypes.PROCESSING_FETCH_GUS_DATA,
  request: {
    url: `/domain/gus/`,
    method: 'post',
    data: { nip }
  },
  meta: {
    asPromise: true
  }
});

export const generateRefundFile = (formData = { objectIds: [] }) => ({
  type: actionTypes.PROCESSING_GENERATE_REFUND_DATA,
  request: {
    url: `/domain/duplo/form/GenerateRefundFile/`,
    method: 'post',
    data: { ObjectIds: formData['_objectIds'] || [], FormData: formData },
    responseType: 'blob'
  },
  meta: {
    asPromise: true
  }
});
