import * as actionTypes from '../actions/actionTypes';

export const fetchFolders = ({ pageId, folderCode = undefined }) => ({
  type: actionTypes.REPORTS_FETCH_FOLDERS,
  request: {
    url: `/Report/Show/GetRootFolder/${folderCode || ''}`,
    method: 'get'
  },
  meta: {
    pageId,
    type: 'foldersTree'
  }
});

export const fetchReports = ({ pageId, folderCode = undefined }) => ({
  type: actionTypes.REPORTS_FETCH_REPORTS,
  request: {
    url: `/Report/Show/GetReportList/${folderCode || ''}`,
    method: 'get'
  },
  meta: {
    pageId,
    type: 'reportsList'
  }
});

export const createFolder = (folderCode, values) => ({
  type: actionTypes.REPORTS_CREATE_FOLDER,
  request: {
    url: `/Report/Show/CreateFolder/${folderCode}`,
    method: 'post',
    data: values
  },
  meta: { asPromise: true }
});

export const deleteReport = (reportCode) => ({
  type: actionTypes.REPORTS_DELETE_REPORT,
  request: {
    url: `/Report/file/DeleteFile/${reportCode}`,
    method: 'delete'
  },
  meta: { asPromise: true }
});

export const fetchReportMetadata = (pageId, reportCode) => {
  return {
    type: actionTypes.REPORTS_FETCH_METADATA,
    request: {
      url: `/Report/Show/GetReportMetadata/${reportCode}`,
      method: 'get'
    },
    meta: { pageId, reportCode, type: 'reportMetadata' }
  };
};

export const previewReport = (
  pageId,
  sessionId,
  reportCode,
  payload = {},
  clear = false
) => {
  return {
    type: actionTypes.REPORTS_PREVIEW,
    data: clear ? { data: [], metadata: {} } : undefined,
    request: {
      url: `/Report/run/PreviewReport/${reportCode}`,
      method: 'post',
      data: sessionId
        ? {
            ...payload,
            sessionId: sessionId
          }
        : payload
    },
    meta: {
      pageId,
      pageIndex: payload.pageIndex || 0,
      type: 'tableRecords'
    }
  };
};

export const downloadReportFile = (reportCode) => ({
  type: actionTypes.REPORTS_DOWNLOAD,
  request: {
    url: `/Report/file/getReportUrl/${reportCode}`,
    method: 'get'
  },
  meta: {
    asPromise: true
  }
});

export const runReportAndDownload = ({
  reportCode,
  fileType = 'XLSX',
  sessionId
}) => ({
  type: actionTypes.REPORTS_RUN_AND_DOWNLOAD,
  request: {
    url: `/Report/run/RunReportAndDownload/${reportCode}/${fileType}`,
    method: 'post',
    data: sessionId ? { sessionId } : {},
    responseType: 'blob'
  },
  meta: {
    asPromise: true
  }
});

export const generateReport = ({
  reportCode,
  outputReportFolder,
  fileType = 'XLSX',
  sessionId
}) => ({
  type: actionTypes.REPORTS_GENERATE,
  request: {
    url: `/Report/run/RunReportAndSave/${reportCode}/${outputReportFolder}/${fileType}`,
    method: 'post',
    data: sessionId ? { sessionId } : {}
  },
  meta: {
    asPromise: true
  }
});

export const generateReportAsync = ({
  reportCode,
  outputReportFolder = '00000000-0000-0000-0000-000000000000',
  fileType = 'XLSX',
  sessionId
}) => ({
  type: actionTypes.REPORTS_GENERATE_ASYNC,
  request: {
    url: `/DocumentProcessing/Report/RunReportAndSaveAsync/${reportCode}/${outputReportFolder}/${fileType}`,
    method: 'post',
    data: sessionId ? { sessionId } : {}
  },
  meta: {
    asPromise: true
  }
});

export const addFilesToFolder = (folderCode, payload) => ({
  type: actionTypes.REPORTS_ADD_FILES_TO_FOLDER,
  request: {
    url: `/Report/file/AddFilesToFolder/${folderCode}`,
    method: 'post',
    data: payload
  },
  meta: {
    asPromise: true
  }
});

export const moveReport = (reportCode, destinationFolder) => ({
  type: actionTypes.REPORTS_MOVE_REPORT,
  request: {
    url: `/Report/file/MoveFile/${reportCode}/${destinationFolder}`,
    method: 'post'
  },
  meta: { asPromise: true }
});

export const renameReport = (reportName, reportDescription, reportCode) => ({
  type: actionTypes.REPORTS_RENAME_REPORT,
  request: {
    url: `/Report/file/RenameFile/${reportCode}`,
    method: 'post',
    data: {
      ReportName: reportName,
      ReportDesc: reportDescription
    }
  },
  meta: { asPromise: true }
});

export const setReportConfigState = (config, pageId) => ({
  type: 'REPORTS_EDITOR_FETCH_CONFIG_SUCCESS',
  meta: {
    pageId,
    type: 'reportsConfig'
  },
  data: config
});

export const getReportConfig = ({ pageId, reportId }) => ({
  type: actionTypes.REPORTS_EDITOR_FETCH_CONFIG,
  request: {
    url: `/Report/config/GetReportConfig/${reportId}`,
    method: 'get',
    data: {}
  },
  meta: {
    pageId,
    type: 'reportsConfig'
  }
});

export const creatorPreviewReport = (
  reportCode,
  queryType,
  queryCode,
  formData = undefined,
  pageIndex = 0,
  pageSize = 10
) => ({
  type: actionTypes.REPORTS_EDITOR_PREVIEW,
  request: {
    url: `/Report/config/RunDevReportPreview/${reportCode}`,
    method: 'post',
    data: {
      queryType,
      queryCode,
      pageIndex,
      pageSize,
      formData
    }
  },
  meta: {
    asPromise: true
  }
});

export const getReportsEditorFilters = () => ({
  type: actionTypes.REPORTS_EDITOR_FETCH_FILTERS,
  request: {
    url: `/Report/config/GetReportFormList`,
    method: 'get'
  },
  meta: {
    asPromise: true
  }
});

export const saveReportChanges = (reportCode, payload) => ({
  type: actionTypes.REPORTS_EDITOR_SAVE_CHANGES,
  request: {
    url: `/Report/config/SaveChangesInReport/${reportCode}`,
    method: 'post',
    data: payload
  },
  meta: {
    asPromise: true
  }
});

export const getReportFilterFormConfig = (filterFormId) => ({
  type: actionTypes.REPORTS_EDITOR_FETCH_FILTER_FORM_CONFIG,
  request: {
    url: `/Report/config/GetFormConfig/${filterFormId}`,
    method: 'get'
  },
  meta: {
    asPromise: true
  }
});
