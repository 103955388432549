import { success, error, abort } from 'redux-saga-requests';
import * as actionTypes from '../actions/actionTypes';
import cloneDeep from 'lodash-es/cloneDeep';

export const reduxFormExtension = (state, action) => {
  switch (action.type) {
    case '@@redux-form/REINITIALIZE':
      // console.log(action);
      // console.log(state);
      const newState = cloneDeep(state);
      if (!newState[action.formId]) newState[action.formId] = {};
      if (action.data) {
        // Prevents viewAsHtml from being cleared on reinit
        // newState[action.formId].values = action.data;

        const currentFormMeta =
          state[action.formId] &&
          state[action.formId].values &&
          state[action.formId].values.formMeta
            ? state[action.formId].values.formMeta
            : {};

        // thumbnail fixer
        const currentThumbFields =
          state[action.formId] && state[action.formId].values
            ? Object.keys(state[action.formId].values).filter((key) =>
                key.startsWith('_thumbnails_')
              )
            : [];
        const reducedThumbfields = currentThumbFields.reduce(
          (acc, fieldKey) => {
            const currentValue = state[action.formId].values[fieldKey] || [];
            const incomingValue = action.data[fieldKey] || [];
            acc[fieldKey] = [...incomingValue];

            currentValue.forEach((el) => {
              if (!acc[fieldKey].find((item) => item.fileId === el.fileId))
                acc[fieldKey].push(el);
            });
            return acc;
          },
          {}
        );

        newState[action.formId].values = {
          ...action.data,
          ...reducedThumbfields,
          formMeta: {
            ...currentFormMeta,
            ...action.data.formMeta
          }
        };
      }
      if (action.errors) {
        const { _error, ...asyncErrors } = action.errors;

        newState[action.formId].asyncErrors = {
          // ...newState[action.formId].syncErrors,
          // ...action.errors
          ...asyncErrors
        };

        if (!newState[action.formId].fields) {
          newState[action.formId].fields = {};
        }

        Object.keys(asyncErrors).forEach((field) => {
          if (!newState[action.formId].fields[field]) {
            newState[action.formId].fields[field] = { touched: true };
          } else {
            newState[action.formId].fields[field].touched = true;
          }
        });

        if (_error) {
          newState[action.formId].error = _error;
          // newState.asyncValidating = false;
        }
      }

      // Setting anytouched seems to fix the onclick event not propagating to buttons on first load
      newState[action.formId].anyTouched = true;
      return newState;
    default:
      return state;
  }
};

const initialState = {
  sessionIds: {},
  fetching: false,
  error: false
};

export const formEngineReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FORMENGINE_INITIALIZE:
      return {
        ...state,
        sessionIds: {
          ...state.sessionIds,
          [action.meta.sessionId]: {
            ...action.meta,
            loading: false
          }
        }
      };
    case actionTypes.FORMENGINE_SERVER_CHANGE:
    case actionTypes.FORMENGINE_SUBMIT_EVENT_SEND:
    case actionTypes.FORMENGINE_WORKFLOW_TRIGGER_SEND:
      const showLoader = action.meta.hideLoader ? false : true;
      return {
        ...state,
        sessionIds: {
          ...state.sessionIds,
          [action.meta.sessionId]: {
            ...state.sessionIds[action.meta.sessionId],
            loading: true,
            showLoader,
            error: false
          }
        },
        fetching: true
      };
    case success(actionTypes.FORMENGINE_SERVER_CHANGE):
      let changes = {};
      // Theoretically dont need updateWithServerValues check here as form wont update without REINITIALIZE anyways.
      if (
        action.data.eventAction === 'Recalculation' &&
        action.meta.updateWithServerValues
      ) {
        const currentLookups =
          state.sessionIds[action.meta.sessionId].lookups || {};
        changes = {
          formData: {
            formMeta: {
              ...action.data.formState
            },
            ...action.data.state
          },
          lookups: {
            ...currentLookups,
            ...action.data.lookups
          },
          validationErrors: { ...action.data.validationErrors }
        };
      }
      return {
        ...state,
        sessionIds: {
          ...state.sessionIds,
          [action.meta.sessionId]: {
            ...state.sessionIds[action.meta.sessionId],
            ...changes,
            loading: false,
            showLoader: false
          }
        },
        fetching: false
      };
    case error(actionTypes.FORMENGINE_SERVER_CHANGE):
    case error(actionTypes.FORMENGINE_SERVER_EVENT):
    case error(actionTypes.FORMENGINE_WORKFLOW_TRIGGER_SEND):
      return {
        ...state,
        sessionIds: {
          ...state.sessionIds,
          [action.meta.sessionId]: {
            ...state.sessionIds[action.meta.sessionId],
            loading: false,
            showLoader: false,
            error: true
          }
        },
        fetching: false
      };
    case abort(actionTypes.FORMENGINE_SERVER_CHANGE):
    case abort(actionTypes.FORMENGINE_SERVER_EVENT):
    case abort(actionTypes.FORMENGINE_WORKFLOW_TRIGGER_SEND):
      return {
        ...state,
        sessionIds: {
          ...state.sessionIds,
          [action.meta.sessionId]: {
            ...state.sessionIds[action.meta.sessionId],
            loading: false,
            showLoader: false,
            error: false
          }
        },
        fetching: false
      };
    case actionTypes.FORMENGINE_SERVER_EVENT:
      const showLoaderEvent = action.meta.hideLoader
        ? false
        : action.meta.updateWithServerValues;
      return {
        ...state,
        sessionIds: {
          ...state.sessionIds,
          [action.meta.sessionId]: {
            ...state.sessionIds[action.meta.sessionId],
            loading: true,
            showLoader: showLoaderEvent
            // showLoader: action.meta.updateWithServerValues
          }
        },
        fetching: true
      };
    case success(actionTypes.FORMENGINE_SERVER_EVENT):
    case success(actionTypes.FORMENGINE_SUBMIT_EVENT_SEND):
    case success(actionTypes.FORMENGINE_WORKFLOW_TRIGGER_SEND):
      return {
        ...state,
        sessionIds: {
          ...state.sessionIds,
          [action.meta.sessionId]: {
            ...state.sessionIds[action.meta.sessionId],
            loading: false,
            showLoader: false
          }
        },
        fetching: false
      };
    default:
      return state;
  }
};

export default formEngineReducer;

// if (node.validate && node.validate.required && name === node.key) {
//   return required({ msg: 'Pole wymagane' })(value);
// }
// case actionTypes.FORMENGINE_REQUEST:
//   return {
//     ...initialState,
//     ids: {
//       ...state.ids,
//       [action.meta.pageId]: {
//         ...state.ids[action.meta.pageId],
//         loading: true,
//         sessionId: null
//       }
//     },
//     fetching: true
//   };
// case success(actionTypes.FORMENGINE_REQUEST):
//   return {
//     ...initialState,
//     ids: {
//       ...state.ids,
//       [action.meta.pageId]: { loading: false, ...action.data }
//     },
//     fetching: false
//     // data: { ...action.data }
//   };
// case error(actionTypes.FORMENGINE_REQUEST):
//   return { ...initialState, ids: { ...state.ids }, error: true };
// case abort(actionTypes.FORMENGINE_REQUEST):
//   return { ...initialState, ids: { ...state.ids }, fetching: false };
