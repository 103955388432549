import React from 'react';
import 'url-search-params-polyfill';

import { hydrate, render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Route } from 'react-router-dom';

// import { Route, Switch } from 'react-router-dom';
// import './index.scss';

import * as serviceWorker from './serviceWorker';
import { store, history, sagaMiddleware } from './store';

import { rootSaga } from './store/sagas';
import LayoutSelector from './LayoutSelector';

import momentRoot from 'moment';
import Moment from 'react-moment';
import 'moment/locale/pl';
import momentLocalizer from 'react-widgets-moment';
import consoleOverride from './consoleOverride';
import L from 'leaflet';

// import './styles/index.less';

require('typeface-lato');

consoleOverride();

momentLocalizer();
momentRoot.locale('pl');
Moment.globalMoment = momentRoot;
Moment.globalLocale = 'pl';

export const moment = momentRoot;

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

sagaMiddleware.run(rootSaga);

const rootElement = document.getElementById('root');

const app = (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Route path="/:selectedMenuKey?" component={LayoutSelector} />
    </ConnectedRouter>
  </Provider>
);

if (rootElement.hasChildNodes()) {
  hydrate(app, rootElement);
} else {
  render(app, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
