import * as actionTypes from '../actions/actionTypes';
// import { success } from 'redux-saga-requests';

// used to reset previous settings
const resetTimestamp = 1679316512155;

const defaultUserSettings = {};

const getUserSettingsFromStorage = () => {
  try {
    let userSettingsFromStorage =
      JSON.parse(localStorage.getItem('userSettings')) || {};
    if (
      !userSettingsFromStorage.timestamp ||
      userSettingsFromStorage.timestamp < resetTimestamp
    ) {
      localStorage.removeItem('userSettings');
      return defaultUserSettings;
    }

    return userSettingsFromStorage;
  } catch (error) {
    console.log('Error parsing user settings, deleting them.');
    localStorage.removeItem('userSettings');
    return defaultUserSettings;
  }
};

let userSettings = getUserSettingsFromStorage();

const initialState = {
  antSettings: {
    fixSiderbar: true,
    fixedHeader: true,
    navTheme: 'light',
    layout: 'topmenu',
    contentWidth: 'Fixed',
    tasksView: 'dropdown',
    language: 'pl',
    debug: false
  },
  userSettings: userSettings
};

const updateAntSettings = (state, action) => {
  if (action.overwrite) {
    return { ...state, antSettings: action.settings };
  } else {
    return {
      ...state,
      antSettings: { ...state.antSettings, ...action.settings }
    };
  }
};

const reloadUserSettings = (state, action) => {
  const userSettings = getUserSettingsFromStorage();
  return { ...state, userSettings };
};

const resetUserSettings = (state, action) => {
  localStorage.removeItem('userSettings');
  return {
    ...state,
    userSettings: {}
  };
};

const updateUserSettings = (state, action) => {
  const { settings, temporary = false } = action;
  const envDate = process.env.REACT_APP_GIT_DATE
    ? new Date(process.env.REACT_APP_GIT_DATE)
    : new Date();
  const timestamp = envDate.valueOf();

  const newUserSettings = { ...state.userSettings, ...settings, timestamp };

  if (!temporary)
    localStorage.setItem('userSettings', JSON.stringify(newUserSettings));
  return { ...state, userSettings: newUserSettings };
};

const toggleLayout = (state, action) => {
  if (state.antSettings.layout === 'topmenu') {
    return {
      ...state,
      antSettings: {
        ...state.antSettings,
        autoHideHeader: true,
        layout: 'sidemenu'
      },
      userSettings: {
        ...userSettings,
        contentWidth: 'Fluid'
      }
    };
  } else {
    return {
      ...state,
      antSettings: {
        ...state.antSettings,
        autoHideHeader: false,
        layout: 'topmenu',
        collapsed: false
      },
      userSettings: {
        ...userSettings,
        contentWidth: 'Fixed'
      }
    };
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_UI_SETTINGS:
      return updateAntSettings(state, action);
    case actionTypes.TOGGLE_UI_LAYOUT:
      return toggleLayout(state, action);
    case actionTypes.RELOAD_USER_SETTINGS:
      return reloadUserSettings(state, action);
    case actionTypes.UPDATE_USER_SETTINGS:
      return updateUserSettings(state, action);
    case actionTypes.RESET_USER_SETTINGS:
      return resetUserSettings(state, action);
    default:
      return state;
  }
};

export default reducer;
