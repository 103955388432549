import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { checkVersion } from './store/actions/index';
import { ConfigProvider } from 'antd';
// import plPL from 'antd/es/locale/pl_PL';
// import enUS from 'antd/es/locale/en_US';
import {
  languageMap,
  translateFunction
} from './components/Translate/Translate';

const AntLayout = React.lazy(() => import('./containers/AntLayout'));

const loading = () => <div className="animated fadeIn pt-3 text-center" />;

class LayoutSelector extends PureComponent {
  componentDidMount() {
    this.checkFocus(); // init

    window.addEventListener('focus', this.checkFocus);
    window.addEventListener('blur', this.checkFocus);
  }

  checkFocus = () => {
    const focus = document.hasFocus();
    if (focus) this.props.checkVersion();
  };

  componentWillUnmount() {
    window.removeEventListener('focus', this.checkFocus);
    window.removeEventListener('blur', this.checkFocus);
  }

  translate = (s) => {
    const { language, debug } = this.props;
    return translateFunction({
      s,
      language,
      debugTxt: debug
    });
  };

  render() {
    const languageObj = languageMap[this.props.language] || languageMap.pl;
    const prefix = window.location.hostname.includes('spb-ubezpieczenia')
      ? 'spb'
      : '';

    return (
      <React.Fragment>
        <Helmet>
          <title>Ładowanie danych... - Ares</title>
          <link rel="shortcut icon" href={`${prefix}/favicon.ico`} />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href={`${prefix}/apple-touch-icon.png`}
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href={`${prefix}/favicon-32x32.png`}
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href={`${prefix}/favicon-16x16.png`}
          />
          {/*           <link rel="manifest" href={`${prefix}/site.webmanifest`} /> */}
          <meta
            name="theme-color"
            content={prefix !== '' ? '#ffffff' : '#566cb9'}
          />
        </Helmet>
        <React.Suspense fallback={loading()}>
          <ConfigProvider locale={languageObj.locale}>
            <AntLayout
              selectedMenuKey={this.props.match.params.selectedMenuKey}
              translate={this.translate}
            />
          </ConfigProvider>
        </React.Suspense>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  const uiSettings = { ...state.ui.antSettings, ...state.ui.userSettings };
  const language = uiSettings.language || 'pl';
  return { language };
};

const mapDispatchToProps = (dispatch) => {
  return { checkVersion: () => dispatch(checkVersion()) };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutSelector);
