const ReloadAndClearCache = () => {
  caches.keys().then(async function (names) {
    console.log(names);
    await Promise.all(names.map((name) => caches.delete(name)));

    // window.location.reload(true);
    const queryStringParams = new URLSearchParams(window.location.search);
    queryStringParams.set('version', `${Date.now()}`);
    const queryString = `?${new URLSearchParams(queryStringParams).toString()}`;
    window.location.search = queryString;
  });
};

export default ReloadAndClearCache;
