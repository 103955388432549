import * as actionTypes from '../actions/actionTypes';

export const getFileUploadUrlPromise = (meta) => ({
  type: actionTypes.FILE_UPLOAD_GET_UPLOAD_URL,
  request: {
    url: `/files/FileStorage/GeneratePreSignedURL`,
    method: 'post',
    data: meta
  },
  meta: { asPromise: true, name: meta.name, size: meta.size, type: meta.type }
});

export const getSharedFileFolders = ({ pageId }) => ({
  type: actionTypes.SHARED_FILES_GET_FOLDERS,
  request: {
    url: `/files/FileStorage/folders`,
    method: 'get'
  },
  meta: { asPromise: true, type: 'foldersTree', pageId }
});

export const getSharedFilesInFolder = ({ folderId = 'null', pageId }) => ({
  type: actionTypes.SHARED_FILES_GET_FILES_IN_FOLDER,
  request: {
    url: `/files/FileStorage/folders/${folderId}/files`,
    method: 'get'
  },
  meta: { asPromise: true, pageId, type: 'reportsList' }
});

export const createSharedFileFolder = (parentId = '', { folderName }) => ({
  type: actionTypes.SHARED_FILES_CREATE_FOLDER,
  request: {
    url: `/files/FileStorage/folders/${parentId}`,
    method: 'post',
    data: { folderName }
  },
  meta: { asPromise: true, folderName }
});

export const addFilesToSharedFileFolder = (folderId, { files = [] }) => ({
  type: actionTypes.SHARED_FILES_ADD_TO_FOLDER,
  request: {
    url: `/files/FileStorage/folders/${folderId}/files`,
    method: 'post',
    data: { files }
  },
  meta: { asPromise: true, folderId, files }
});
export const moveFilesToSharedFileFolder = (folderId, files = []) => ({
  type: actionTypes.SHARED_FILES_MOVE_TO_FOLDER,
  request: {
    url: `/files/FileStorage/folders/move-files/${folderId}`,
    method: 'post',
    data: { files }
  },
  meta: { asPromise: true, folderId, files }
});
export const moveSharedFileFolder = (folderId, parentFolderId) => ({
  type: actionTypes.SHARED_FILES_MOVE_FOLDER,
  request: {
    url: `/files/FileStorage/folders/move/${parentFolderId}`,
    method: 'post',
    data: { FolderId: folderId }
  },
  meta: { asPromise: true, folderId, parentFolderId }
});

export const deleteSharedFile = (fileId) => ({
  type: actionTypes.SHARED_FILES_DELETE_FILE,
  request: {
    url: `/files/FileStorage/folders/files/${fileId}`,
    method: 'delete'
  },
  meta: { asPromise: true, fileId }
});
export const deleteSharedFileFolder = (folderId) => ({
  type: actionTypes.SHARED_FILES_DELETE_FOLDER,
  request: {
    url: `/files/FileStorage/folders/${folderId}`,
    method: 'delete'
  },
  meta: { asPromise: true, folderId }
});

export const renameSharedFile = (name, fileId) => ({
  type: actionTypes.SHARED_FILES_RENAME_FILE,
  request: {
    url: `/files/FileStorage/folders/files/${fileId}`,
    method: 'put',
    data: { filename: name }
  },
  meta: { asPromise: true, fileId }
});

export const renameSharedFileFolder = (name, folderId) => ({
  type: actionTypes.SHARED_FILES_RENAME_FOLDER,
  request: {
    url: `/files/FileStorage/folders/${folderId}`,
    method: 'put',
    data: { folderName: name }
  },
  meta: { asPromise: true, folderId }
});
