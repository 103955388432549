import { put, debounce, select } from 'redux-saga/effects';

import * as actions from '../actions/index';
import * as actionTypes from '../actions/actionTypes';

function* fetchSearchResults(action) {
  const fleetId = yield select((state) => state.auth.user.fleetId);

  if (fleetId) action.meta.fleetId = fleetId;

  yield put(actions.fetchSearchResultsRequest(action));
}

export function* debounceSearch() {
  yield debounce(500, actionTypes.FETCH_SEARCH_RESULTS, fetchSearchResults);
}
