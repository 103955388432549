import * as actionTypes from '../actions/actionTypes';
import { success } from 'redux-saga-requests';

export const fetchSearchResults = (query) => ({
  type: actionTypes.FETCH_SEARCH_RESULTS,
  meta: {
    query
  }
});

export const fetchSearchResultsRequest = (action) => {
  if (action.meta.query.length > 0) {
    let query = action.meta.query;
    if (query.includes('+')) query = query.split('+')[0];

    if (action.meta.fleetId) query += `&fleetId=${action.meta.fleetId}`;

    return {
      type: actionTypes.FETCH_SEARCH_RESULTS_REQUEST,
      request: {
        url: `/domain/globalSearch?query=${query}`,
        method: 'get'
      },
      meta: {
        action
      }
    };
  } else {
    return {
      type: success(actionTypes.FETCH_SEARCH_RESULTS_REQUEST),
      data: { data: [] }
    };
  }
};
