import * as actionTypes from '../actions/actionTypes';

export const fetchTasks = (meta) => {
  return {
    type: actionTypes.FETCH_TASKS_REQUEST,
    request: {
      url: `/domain/task`,
      method: 'get'
    },
    meta: {
      meta
    }
  };
};

export const openTasks = () => {
  return {
    type: actionTypes.TASK_DRAWER_VISIBILITY,
    open: true
  };
};
export const closeTasks = () => {
  return {
    type: actionTypes.TASK_DRAWER_VISIBILITY,
    open: false
  };
};
export const toggleTasks = () => {
  return {
    type: actionTypes.TASK_DRAWER_VISIBILITY_TOGGLE
  };
};

export const completeTask = (meta) => {
  const { taskId } = meta;
  return {
    type: actionTypes.COMPLETE_TASK_REQUEST,
    request: {
      url: `/domain/task/completeTask/${taskId}`,
      method: 'POST',
      data: {}
    },
    meta: {
      meta,
      asPromise: true
    }
  };
};

export const postponeTask = ({ taskId, date }) => {
  // const { taskId } = meta;
  return {
    type: actionTypes.POSTPONE_TASK_REQUEST,
    request: {
      url: `/domain/task/postpone/${taskId}`,
      method: 'POST',
      data: { visibleAfterDT: date }
    },
    meta: {
      // meta,
      asPromise: true
    }
  };
};
