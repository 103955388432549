import * as actionTypes from '../actions/actionTypes';

export const fetchObjectHistory = ({ pageId, objectId, formId = null }) => ({
  type: actionTypes.HISTORY_FETCH_OBJECT_CHANGES,
  request: {
    url: (`/domain/ObjectDataHistory/${objectId}` + (formId ? `/${formId}` : '')),
    method: 'get'
  },
  meta: {
    pageId,
    type: 'history'
  }
});
