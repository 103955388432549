import * as actionTypes from '../actions/actionTypes';

const trimObjectProperties = (obj) => {
  if (typeof obj === 'object' && obj !== null) {
    for (var value in obj) {
      if (obj.hasOwnProperty(value) && typeof obj[value] === 'string') {
        obj[value] = obj[value].trim();
      }
    }
  }
  return obj;
};

export const fetchTableRecords = (data) => ({
  type: actionTypes.TABLE_RECORDS_FETCH,
  data
});

export const fetchTableRecordsRequest = ({
  pageId,
  queryStringParams,
  pageIndex = 0,
  pageSize,
  formData = undefined,
  orderBy = undefined,
  search = '',
  sessionId,
  noPaging = false,
  topFilterData
}) => ({
  type: actionTypes.TABLE_RECORDS_FETCH_REQUEST,
  request: {
    url: `/domain/list/data/${pageId}`,
    method: 'post',
    params: queryStringParams,
    data: {
      pageIndex,
      pageSize,
      formData: trimObjectProperties(formData),
      orderBy,
      search: search.trim(),
      sessionId,
      noPaging
    }
  },
  meta: {
    pageId,
    pageIndex,
    type: 'tableRecords',
    topFilterData
  }
});

export const tableExportToFile = ({
  pageId,
  queryStringParams,
  pageIndex = 0,
  pageSize,
  formData = undefined,
  orderBy = undefined,
  sessionId,
  search = '',
  format = 'XLS',
  noPaging = false
}) => ({
  type: actionTypes.TABLE_EXPORT_FETCH,
  request: {
    url: `/domain/list/exportToFile/${pageId}/${format}`,
    method: 'post',
    params: queryStringParams,
    data: {
      pageIndex,
      pageSize,
      formData,
      orderBy,
      search: search.trim(),
      sessionId,
      noPaging
    },
    responseType: 'blob'
  },
  meta: {
    pageId,
    pageIndex,
    type: 'tableExport'
  }
});

export const makeCustomRequest = (url, method, data = {}) => ({
  type: actionTypes.MAKE_CUSTOM_REQUEST,
  request: {
    url: url,
    method: method,
    data: data
  },
  meta: { asPromise: true }
});

export const tableOpenModal = (values) => {
  return {
    type: actionTypes.TABLE_OPEN_MODAL,
    payload: values
  };
};
