import * as actionTypes from '../actions/actionTypes';
import { success, error } from 'redux-saga-requests';

const initialState = {
  open: false,
  loading: false,
  error: false,
  data: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TASK_DRAWER_VISIBILITY:
      return {
        ...state,
        open: action.open
      };
    case actionTypes.TASK_DRAWER_VISIBILITY_TOGGLE:
      return {
        ...state,
        open: !state.open
      };
    case actionTypes.COMPLETE_TASK_REQUEST:
      return {
        ...state,
        error: false,
        loading: true
      };
    case error(actionTypes.COMPLETE_TASK_REQUEST):
      return {
        ...state,
        error: action.error,
        loading: false
      };
    case success(actionTypes.COMPLETE_TASK_REQUEST):
      return {
        ...state,
        error: false,
        loading: false
      };
    case actionTypes.FETCH_TASKS_REQUEST:
      return {
        ...state,
        // data: [],
        error: false,
        loading: true
      };
    case error(actionTypes.FETCH_TASKS_REQUEST):
      return {
        ...state,
        data: [],
        error: action.error,
        loading: false
      };
    case success(actionTypes.FETCH_TASKS_REQUEST):
      return {
        ...state,
        data: action.data.tasks,
        // data: action.data.tasks.slice(0, 100),
        error: false,
        loading: false
      };
    default:
      return state;
  }
};

export default reducer;
