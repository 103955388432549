import * as actionTypes from './actionTypes';

export const fetchFleets = (pageId, objectId) => ({
  type: actionTypes.FLEET_FETCH_ALL,
  request: {
    url: `/domain/fleet/tree` + (objectId ? `/filter/${objectId}` : ''),
    method: 'get'
  },
  meta: {
    pageId,
    type: 'fleet'
  }
});

export const fetchSingleFleet = (fleetId) => ({
  type: actionTypes.FLEET_FETCH_SINGLE,
  request: {
    url: `/domain/fleet/tree/${fleetId}`,
    method: 'get'
  },
  meta: {
    asPromise: true
  }
});

export const createFleet = ({ parentFleetUnitId, ...rest }) => ({
  type: actionTypes.FLEET_CREATE,
  request: {
    url: `/domain/fleet/tree`,
    method: 'post',
    data: {
      parentFleetUnitId,
      ...rest
    }
  },
  meta: {
    asPromise: true
  }
});

export const updateFleet = (fleetId, fleetData) => ({
  type: actionTypes.FLEET_UPDATE,
  request: {
    url: `/domain/fleet/tree/${fleetId}`,
    method: 'put',
    data: {
      ...fleetData
    }
  },
  meta: {
    asPromise: true
  }
});

export const moveFleet = (fleetId, sourceParentId, targetParentId) => ({
  type: actionTypes.FLEET_MOVE,
  request: {
    url: `/domain/fleet/tree/update-parent/${fleetId}`,
    method: 'post',
    data: {
      sourceParentId,
      targetParentId
    }
  },
  meta: {
    asPromise: true
  }
});

export const getFleetPermissionDictionaries = (pageId) => ({
  type: actionTypes.FLEET_PERMISSION_DICTIONARIES,
  request: {
    url: `/identity/FleetPermissions/Dictionaries`,
    method: 'get'
  },
  meta: {
    // asPromise: true
    pageId,
    type: 'fleetDictionaries'
  }
});

export const getFleetPermissionUsers = (fleetId) => ({
  type: actionTypes.FLEET_PERMISSION_USERS,
  request: {
    url: `/identity/FleetPermissions/${fleetId}/users`,
    method: 'get'
  },
  meta: {
    asPromise: true
  }
});

export const deleteFleetNode = (fleetNodeId) => ({
  type: actionTypes.FLEET_DELETE,
  request: {
    url: `/domain/fleet/tree/remove/${fleetNodeId}`,
    method: 'delete'
  },
  meta: {
    asPromise: true
  }
});

//roles=[{roleId,roleName}]
export const createFleetUserPermission = ({
  applicationId,
  applicationName,
  userId,
  roles,
  fleetId,
  fleetName
}) => ({
  type: actionTypes.FLEET_PERMISSION_USERS_CREATE,
  request: {
    url: `/identity/FleetPermissions/user`,
    method: 'post',
    data: { applicationId, applicationName, userId, roles, fleetId, fleetName }
  },
  meta: {
    asPromise: true
  }
});

export const deleteFleetPermission = (permissionId) => ({
  type: actionTypes.FLEET_PERMISSION_DELETE,
  request: {
    url: `/identity/FleetPermissions/${permissionId}`,
    method: 'delete'
  },
  meta: {
    asPromise: true
  }
});

export const getFilteredFleet = (fleetId) => ({
  type: actionTypes.FLEET_NODE_FILTER,
  request: {
    url: `/domain/fleet/tree/filter/${fleetId}`,
    method: 'get'
  },
  meta: {
    asPromise: true
  }
});

export const createFleetUser = (payload) => ({
  type: actionTypes.FLEET_USERS_CREATE,
  request: {
    url: `/identity/FleetUsers/Create`,
    method: 'post',
    data: payload
  },
  meta: {
    asPromise: true
  }
});
