import { put, select } from 'redux-saga/effects';
import * as actions from '../actions';

// import { autofill } from 'redux-form';
// import cloneDeep from 'lodash-es/cloneDeep';
// import ObservableSlim from 'observable-slim';
// import { push } from 'connected-react-router';
// import { getFormSyncErrors, touch } from 'redux-form';

export function* beforeDynamicSaga(action) {
  const { pageId, queryString } = action.data;
  let payload = action.data.payload;
  let triggeredByAuthSaga = action.data.triggeredByAuthSaga;
  let nestedDynamic = action.data.nestedDynamic;

  const dynamicRecord = yield select((state) => state.dynamic.ids[pageId]);

  if (dynamicRecord) {
    switch (dynamicRecord.pageTemplate) {
      case 'DataTableTemplate':
        if (dynamicRecord.topFilterData) {
          payload = { ...dynamicRecord.topFilterData, ...payload };
        }
        break;
      default:
        break;
    }
  }

  yield put(
    actions.fetchDynamicPageRequest(
      pageId,
      queryString,
      payload,
      false,
      triggeredByAuthSaga,
      nestedDynamic
    )
  );
}

export function* afterDynamicSaga(action) {
  // yield console.log('action', action);
  // yield put(actions.checkVersion());

  switch (action.data.pageTemplate) {
    case 'TabPageTemplate':
      if (action.meta.onlyHeader) {
        const { config = {} } = action.data;
        const { headingConfig = {} } = config;
        const { data } = headingConfig;
        const formId = `${action.meta.pageId}heading`;
        const errors = null;
        yield put({
          type: '@@redux-form/REINITIALIZE',
          data: data,
          formId,
          errors
        });
      }
      break;
    case 'DocumentsPageTemplate':
      yield put(
        actions.fetchAllDocuments({
          pageId: action.data.pageId,
          queryString: action.meta.queryString
        })
      );
      break;
    case 'DocumentsPageTemplateByObjectId':
      yield put(
        actions.fetchDocumentsByObjectId({
          objectId: action.meta.objectId,
          pageId: action.data.pageId,
          queryString: action.meta.queryString
        })
      );

      break;
    case 'JournalPageTemplate':
      const splitCategories =
        action.data.settings.showOnlySpecificCategory &&
        action.data.settings.showOnlySpecificCategory !== ''
          ? action.data.settings.showOnlySpecificCategory.split(';')
          : null;
      yield put(
        actions.fetchJournal({
          pageId: action.data.pageId,
          queryString: action.meta.queryString,
          showOnlySpecificCategory: splitCategories
        })
      );
      break;
    case 'JournalDocumentsPageTemplate':
      yield put(
        actions.fetchJournalDocuments({
          pageId: action.data.pageId,
          queryString: action.meta.queryString
        })
      );
      break;
    case 'ObjectChangeHistoryTemplate':
      yield put(
        actions.fetchObjectHistory({
          pageId: action.data.pageId,
          objectId: action.meta.objectId,
          formId:
            action.data && action.data.settings && action.data.settings.formId
              ? action.data.settings.formId.toLowerCase()
              : null
        })
      );
      break;
    case 'FleetPageTemplate':
      yield put(actions.fetchFleets(action.data.pageId, action.meta.objectId));
      break;
    case 'DataTableTemplate':
    case 'JournalEmailMatcherTemplate':
    case 'TVPImportPageTemplate':
    case 'EmployeesConfirmProposalPageTemplate':
    case 'BankImportPageTemplate':
    case 'MassFileUploadTemplate':
      let sessionId;
      let pageSize;
      let noPaging;
      if (action.data && action.data.config && action.data.config.formConfig) {
        sessionId = action.data.config.formConfig.sessionId;
      }
      if (
        action.data &&
        action.data.settings &&
        action.data.settings.defaultPageSize
      ) {
        const size = action.data.settings.defaultPageSize;
        if (size === '0') noPaging = true;
        else pageSize = size;
      }

      if (action.meta.nestedDynamic) noPaging = true;

      const location = yield select((state) => state.router.location);
      const { search } = location;
      const queryStringParams = new URLSearchParams(search);
      const searchQuery = queryStringParams.get('q');

      const dynamicRecord = yield select(
        (state) => state.dynamic.ids[action.data.pageId]
      );
      let sourceParams = {};

      if (
        dynamicRecord &&
        dynamicRecord.sourceParams &&
        dynamicRecord.sourceParams.objectId === action.meta.objectId
      ) {
        sourceParams = dynamicRecord.sourceParams;
      }

      const fetchData = {
        pageId: action.data.pageId,
        queryStringParams: action.meta.queryString
          ? action.meta.queryString.startsWith('?')
            ? new URLSearchParams(action.meta.queryString.substring(1))
            : new URLSearchParams(action.meta.queryString)
          : queryStringParams,
        pageSize,
        noPaging,
        ...sourceParams,
        sessionId,
        search: searchQuery || sourceParams.search
      };

      if (action.meta.topFilterData) {
        fetchData.topFilterData = action.meta.topFilterData;
      }

      yield put(actions.fetchTableRecords(fetchData));
      break;
    case 'ReportsPageTemplate':
      const queryString = yield select((state) => state.router.location.search);
      const searchParams = new URLSearchParams(queryString);
      const reportId = searchParams.get('reportId');
      const type = searchParams.get('type');
      const folderFromParams = searchParams.get('reportFolder');
      const folderCode = folderFromParams
        ? folderFromParams
        : localStorage.getItem('lastVisitedFolder') || '';
      const { pageId } = action.meta;
      const isPreviewTemplate = reportId && !type;
      const isEditorTemplate =
        type && reportId && reportId !== '00000000-0000-0000-0000-000000000000';
      const isReportsTemplate = !reportId && !type;
      if (isPreviewTemplate) {
        yield put(actions.fetchReportMetadata(pageId, reportId));
      } else if (isEditorTemplate) {
        yield put(
          actions.getReportConfig({
            pageId,
            reportId
          })
        );
      } else if (isReportsTemplate) {
        yield put(
          actions.fetchReports({
            pageId,
            folderCode
          })
        );
      }
      yield put(
        actions.fetchFolders({
          pageId,
          folderCode
        })
      );
      break;
    case 'ObjectDocumentsPageTemplate':
      yield put(actions.fetchDocumentsByObjectIdTvp(action.meta.objectId));
      break;
    case 'SharedFilesPageTemplate': {
      const folderId = localStorage.getItem('lastVisitedSharedFolder') || '';
      const { pageId } = action.meta;

      yield put(
        actions.getSharedFileFolders({
          pageId,
          folderId
        })
      );
      if (folderId !== '')
        yield put(
          actions.getSharedFilesInFolder({
            pageId,
            folderId
          })
        );
      break;
    }
    case 'UsersManagementPageTemplate':
      yield put(actions.getFleetPermissionDictionaries(action.data.pageId));
      break;
    // case 'LettersPageTemplate':
    //   yield put(actions.fetchLetterTemplates(action.data.pageId));
    //   break;
    default:
      break;
  }
  // const data = { ...action.meta.initialData };
  // const formId = action.meta.formId;
}
